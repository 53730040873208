import React from "react"
import {Box, Link, Typography} from "@material-ui/core"

const Contact = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100%"
  >
    <Box>
      <Typography>
          Gregory Ball<br/>
          Solution Architect at VMware<br/>
          Email: <Link href = "mailto: greg@baller.software" variant="inherit">greg@baller.software</Link>
      </Typography>
    </Box>
  </Box>
)

export default Contact
